@font-face {
font-family: '__publicSans_56c3e5';
src: url(/_next/static/media/8bf7b2ceda89477b-s.p.woff2) format('woff2');
font-display: swap;
font-weight: 100 900;
}@font-face {font-family: '__publicSans_Fallback_56c3e5';src: local("Arial");ascent-override: 92.25%;descent-override: 21.85%;line-gap-override: 0.00%;size-adjust: 102.98%
}.__className_56c3e5 {font-family: '__publicSans_56c3e5', '__publicSans_Fallback_56c3e5'
}.__variable_56c3e5 {--font-public-sans: '__publicSans_56c3e5', '__publicSans_Fallback_56c3e5'
}

.m_468e7eda {
  padding-top: 0;
  padding-bottom: 0;
  appearance: none;
}

  .m_468e7eda::-webkit-calendar-picker-indicator {
    display: none;
  }

  .m_468e7eda::-webkit-clear-button {
    display: none;
  }

  .m_468e7eda::-webkit-datetime-edit-hour-field,
  .m_468e7eda::-webkit-datetime-edit-minute-field,
  .m_468e7eda::-webkit-datetime-edit-second-field,
  .m_468e7eda::-webkit-datetime-edit-ampm-field {
    padding-top: 0;
    max-height: calc(1.875rem * var(--mantine-scale));
    display: inline;
  }

  .m_468e7eda::-webkit-datetime-edit-hour-field:focus, .m_468e7eda::-webkit-datetime-edit-minute-field:focus, .m_468e7eda::-webkit-datetime-edit-second-field:focus, .m_468e7eda::-webkit-datetime-edit-ampm-field:focus {
      background-color: var(--mantine-primary-color-filled);
      color: var(--mantine-color-white);
    }

.m_396ce5cb {
  --day-size-xs: calc(1.875rem * var(--mantine-scale));
  --day-size-sm: calc(2.25rem * var(--mantine-scale));
  --day-size-md: calc(2.625rem * var(--mantine-scale));
  --day-size-lg: calc(3rem * var(--mantine-scale));
  --day-size-xl: calc(3.375rem * var(--mantine-scale));
  --day-size: var(--day-size-sm);

  width: var(--day-size, var(--day-size-sm));
  height: var(--day-size, var(--day-size-sm));
  font-size: calc(var(--day-size) / 2.8);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background-color: transparent;
  border-radius: var(--mantine-radius-default);
  color: var(--mantine-color-text);
  opacity: 1;
}

  @media (hover: hover) {
      [data-mantine-color-scheme='light'] .m_396ce5cb:hover:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m_396ce5cb:hover:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  @media (hover: none) {
      [data-mantine-color-scheme='light'] .m_396ce5cb:active:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m_396ce5cb:active:where(:not([data-static], [data-disabled], [data-selected], [data-in-range])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  .m_396ce5cb:where([data-static]) {
    user-select: auto;
    cursor: default;
  }

  .m_396ce5cb:where([data-weekend]) {
    color: var(--mantine-color-red-6);
  }

  .m_396ce5cb:where([data-outside]) {
    color: var(--mantine-color-dimmed);
    opacity: 0.5;
  }

  .m_396ce5cb:where(:disabled, [data-disabled]) {
    color: var(--mantine-color-dimmed);
    cursor: not-allowed;
    opacity: 0.5;
  }

  .m_396ce5cb:where([data-hidden]) {
    display: none;
  }

  :where([data-mantine-color-scheme='light']) .m_396ce5cb:where([data-today][data-highlight-today]:not([data-selected], [data-in-range])) {
      border: 1px solid var(--mantine-color-gray-4);
}

  :where([data-mantine-color-scheme='dark']) .m_396ce5cb:where([data-today][data-highlight-today]:not([data-selected], [data-in-range])) {
      border: 1px solid var(--mantine-color-dark-4);
}

  .m_396ce5cb:where([data-in-range]) {
    background-color: var(--mantine-primary-color-light-hover);
    border-radius: 0;
  }

  @media (hover: hover) {
      .m_396ce5cb:where([data-in-range]):hover:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-light);
      }
}

  @media (hover: none) {
      .m_396ce5cb:where([data-in-range]):active:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-light);
      }
}

  .m_396ce5cb:where([data-first-in-range]) {
    border-radius: 0;
    border-start-start-radius: var(--mantine-radius-default);
    border-end-start-radius: var(--mantine-radius-default);
  }

  .m_396ce5cb:where([data-last-in-range]) {
    border-radius: 0;
    border-end-end-radius: var(--mantine-radius-default);
    border-start-end-radius: var(--mantine-radius-default);
  }

  .m_396ce5cb:where([data-last-in-range][data-first-in-range]) {
    border-radius: var(--mantine-radius-default);
  }

  .m_396ce5cb:where([data-selected]) {
    background-color: var(--mantine-primary-color-filled);
    color: var(--mantine-primary-color-contrast);
  }

  @media (hover: hover) {
      .m_396ce5cb:where([data-selected]):hover:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-filled-hover);
      }
}

  @media (hover: none) {
      .m_396ce5cb:where([data-selected]):active:where(:not([data-disabled], [data-static])) {
        background-color: var(--mantine-primary-color-filled-hover);
      }
}

.m_18a3eca {
  color: var(--mantine-color-dimmed);
  font-weight: normal;
  font-size: var(--wr-fz, var(--mantine-font-size-sm));
  text-transform: capitalize;
  padding-bottom: calc(var(--wr-spacing, var(--mantine-spacing-sm)) / 2);
}

.m_cc9820d3 {
  border-collapse: collapse;
  table-layout: fixed;
}

.m_8f457cd5 {
  padding: 0;
}

.m_8f457cd5:where([data-with-spacing]) {
    padding: calc(0.03125rem * var(--mantine-scale));
  }

.m_6cff9dea {
  --wn-size-xs: calc(1.875rem * var(--mantine-scale));
  --wn-size-sm: calc(2.25rem * var(--mantine-scale));
  --wn-size-md: calc(2.625rem * var(--mantine-scale));
  --wn-size-lg: calc(3rem * var(--mantine-scale));
  --wn-size-xl: calc(3.375rem * var(--mantine-scale));

  color: var(--mantine-color-dimmed);
  font-weight: normal;
  font-size: calc(var(--wn-size, var(--wn-size-sm)) / 2.8);
  text-align: center;
  width: var(--wn-size, var(--wn-size-sm));
}

.m_dc6a3c71 {
  --dpc-size-xs: calc(1.875rem * var(--mantine-scale));
  --dpc-size-sm: calc(2.25rem * var(--mantine-scale));
  --dpc-size-md: calc(2.625rem * var(--mantine-scale));
  --dpc-size-lg: calc(3rem * var(--mantine-scale));
  --dpc-size-xl: calc(3.375rem * var(--mantine-scale));
  --dpc-size: var(--dpc-size-sm);

  font-size: var(--dpc-fz, var(--mantine-font-size-sm));
  height: var(--dpc-size);
  width: calc((var(--dpc-size) * 7) / 3 + calc(0.09375rem * var(--mantine-scale)));
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  cursor: pointer;
  background-color: transparent;
  color: var(--mantine-color-text);
  opacity: 1;
  border-radius: var(--mantine-radius-default);
}

  @media (hover: hover) {
      :where([data-mantine-color-scheme='light']) .m_dc6a3c71:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_dc6a3c71:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

  @media (hover: none) {
      :where([data-mantine-color-scheme='light']) .m_dc6a3c71:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_dc6a3c71:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

  .m_dc6a3c71:where(:disabled, [data-disabled]) {
    color: var(--mantine-color-dimmed);
    cursor: not-allowed;
    opacity: 0.5;
  }

  .m_dc6a3c71:where([data-selected]) {
    background-color: var(--mantine-primary-color-filled);
    color: var(--mantine-primary-color-contrast, var(--mantine-color-white));
  }

  @media (hover: hover) {

  .m_dc6a3c71:where([data-selected]):hover {
      background-color: var(--mantine-primary-color-filled-hover);
  }
}

  @media (hover: none) {

  .m_dc6a3c71:where([data-selected]):active {
      background-color: var(--mantine-primary-color-filled-hover);
  }
}

  .m_dc6a3c71:where([data-in-range]) {
    background-color: var(--mantine-primary-color-light-hover);
    border-radius: 0;
  }

  @media (hover: hover) {

  .m_dc6a3c71:where([data-in-range]):hover {
      background-color: var(--mantine-primary-color-light);
  }
}

  @media (hover: none) {

  .m_dc6a3c71:where([data-in-range]):active {
      background-color: var(--mantine-primary-color-light);
  }
}

  .m_dc6a3c71:where([data-first-in-range]) {
    border-radius: 0;
    border-start-start-radius: var(--mantine-radius-default);
    border-end-start-radius: var(--mantine-radius-default);
  }

  .m_dc6a3c71:where([data-last-in-range]) {
    border-radius: 0;
    border-end-end-radius: var(--mantine-radius-default);
    border-start-end-radius: var(--mantine-radius-default);
  }

  .m_dc6a3c71:where([data-first-in-range][data-last-in-range]) {
    border-radius: var(--mantine-radius-default);
  }

.m_9206547b {
  border-collapse: collapse;
  border-width: 0;
}

.m_c5a19c7d {
  padding: 0;
}

.m_c5a19c7d:where([data-with-spacing]) {
    padding: calc(0.03125rem * var(--mantine-scale));
  }

.m_2a6c32d {
  border-collapse: collapse;
  border-width: 0;
  cursor: pointer;
}

.m_fe27622f {
  padding: 0;
}

.m_fe27622f:where([data-with-spacing]) {
    padding: calc(0.03125rem * var(--mantine-scale));
  }

.m_730a79ed {
  --dch-control-size-xs: calc(1.875rem * var(--mantine-scale));
  --dch-control-size-sm: calc(2.25rem * var(--mantine-scale));
  --dch-control-size-md: calc(2.625rem * var(--mantine-scale));
  --dch-control-size-lg: calc(3rem * var(--mantine-scale));
  --dch-control-size-xl: calc(3.375rem * var(--mantine-scale));
  --dch-control-size: var(--dch-control-size-sm);

  display: flex;
  max-width: calc(var(--dch-control-size) * 8 + calc(0.4375rem * var(--mantine-scale)));
  margin-bottom: var(--mantine-spacing-xs);
}

.m_f6645d97,
.m_2351eeb0 {
  height: var(--dch-control-size);
  border-radius: var(--mantine-radius-default);
  display: flex;
  justify-content: center;
  align-items: center;
  user-select: none;
  opacity: 1;
  cursor: pointer;
}

@media (hover: hover) {
      [data-mantine-color-scheme='light'] .m_f6645d97:hover:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='light'] .m_2351eeb0:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m_f6645d97:hover:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='dark'] .m_2351eeb0:hover:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

@media (hover: none) {
      [data-mantine-color-scheme='light'] .m_f6645d97:active:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='light'] .m_2351eeb0:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-gray-0);
  }

      [data-mantine-color-scheme='dark'] .m_f6645d97:active:where(:not([data-disabled], :disabled)), [data-mantine-color-scheme='dark'] .m_2351eeb0:active:where(:not([data-disabled], :disabled)) {
        background-color: var(--mantine-color-dark-5);
  }
}

.m_f6645d97:where(:disabled, [data-disabled]), .m_2351eeb0:where(:disabled, [data-disabled]) {
    opacity: 0.2;
    cursor: not-allowed;
  }

.m_2351eeb0 {
  width: var(--dch-control-size);
}

.m_f6645d97 {
  flex: 1;
  font-size: var(--dch-fz, var(--mantine-font-size-sm));
  font-weight: 500;
  text-transform: capitalize;
}

.m_367dc749 {
  width: 60%;
  height: 60%;
}

.m_367dc749:where([data-direction='next']) {
    transform: rotate(270deg);
  }

:where([dir="rtl"]) .m_367dc749:where([data-direction='next']) {
      transform: rotate(90deg);
}

.m_367dc749:where([data-direction='previous']) {
    transform: rotate(90deg);
  }

:where([dir="rtl"]) .m_367dc749:where([data-direction='previous']) {
      transform: rotate(270deg);
}

.m_30b26e33 {
  display: flex;
  gap: var(--mantine-spacing-md);
}

.m_6fa5e2aa {
  cursor: pointer;
  line-height: unset;
}

  .m_6fa5e2aa:where([data-read-only]) {
    cursor: default;
  }

.m_208d2562 {
  display: flex;
  align-items: stretch;
  margin-top: var(--mantine-spacing-md);
}

.m_62ee059 {
  flex: 1;
  margin-inline-end: var(--mantine-spacing-md);
}

.m_dd3f7539 {
  position: relative;
  border: calc(0.0625rem * var(--mantine-scale)) solid;
  border-radius: var(--mantine-radius-default);
}

  :where([data-mantine-color-scheme='light']) .m_dd3f7539 {
    border-color: var(--mantine-color-gray-4);
}

  :where([data-mantine-color-scheme='dark']) .m_dd3f7539 {
    border-color: var(--mantine-color-dark-4);
}

.m_d37966d9 {
  padding: 0;
  margin: 0;
}

.m_d37966d9 li > p {
    display: inline-block;
    margin: 0;
  }

.m_d37966d9 ul li,
  .m_d37966d9 ol li {
    margin-top: calc(0.125rem * var(--mantine-scale));
  }

.m_d37966d9 p {
    margin-bottom: calc(0.4375rem * var(--mantine-scale));
  }

.m_d37966d9 h1,
  .m_d37966d9 h2,
  .m_d37966d9 h3,
  .m_d37966d9 h4,
  .m_d37966d9 h5,
  .m_d37966d9 h6,
  .m_d37966d9 p {
    margin-top: 0;
  }

.m_c2204cc2 {
  background-color: var(--mantine-color-body);
  border-radius: var(--mantine-radius-default);
}

.m_c2204cc2 .ProseMirror {
    outline: 0;
    padding: var(--mantine-spacing-md);
  }

.m_c2204cc2 .ProseMirror > *:last-child {
    margin-bottom: 0;
  }

.m_c2204cc2 .ProseMirror p.is-editor-empty:first-of-type::before {
    content: attr(data-placeholder);
    pointer-events: none;
    user-select: none;
    float: left;
    height: 0;
    color: var(--mantine-color-placeholder);
  }

.m_c2204cc2 pre {
    font-family: var(--mantine-font-family-monospace);
    border-radius: var(--mantine-radius-default);
    padding: var(--mantine-spacing-sm) var(--mantine-spacing-md);
  }

:where([data-mantine-color-scheme='light']) .m_c2204cc2 pre {
      background-color: var(--mantine-color-gray-0);
      color: var(--mantine-color-gray-9);
      --code-color-comment: var(--mantine-color-gray-5);
      --code-color-var: var(--mantine-color-red-7);
      --code-color-number: var(--mantine-color-blue-7);
      --code-color-title: var(--mantine-color-pink-7);
      --code-color-keyword: var(--mantine-color-violet-7);
}

:where([data-mantine-color-scheme='dark']) .m_c2204cc2 pre {
      background-color: var(--mantine-color-dark-8);
      color: var(--mantine-color-dark-1);
      --code-color-comment: var(--mantine-color-dark-2);
      --code-color-var: var(--mantine-color-red-5);
      --code-color-number: var(--mantine-color-cyan-5);
      --code-color-title: var(--mantine-color-yellow-5);
      --code-color-keyword: var(--mantine-color-violet-3);
}

.m_c2204cc2 pre code {
      background: none !important;
      color: inherit;
      font-size: var(--mantine-font-size-sm);
      padding: 0;
    }

.m_c2204cc2 pre .hljs-comment,
    .m_c2204cc2 pre .hljs-quote {
      color: var(--code-color-comment);
    }

.m_c2204cc2 pre .hljs-variable,
    .m_c2204cc2 pre .hljs-template-variable,
    .m_c2204cc2 pre .hljs-attribute,
    .m_c2204cc2 pre .hljs-tag,
    .m_c2204cc2 pre .hljs-regexp,
    .m_c2204cc2 pre .hljs-link,
    .m_c2204cc2 pre .hljs-name,
    .m_c2204cc2 pre .hljs-selector-id,
    .m_c2204cc2 pre .hljs-selector-class {
      color: var(--code-color-var);
    }

.m_c2204cc2 pre .hljs-number,
    .m_c2204cc2 pre .hljs-meta,
    .m_c2204cc2 pre .hljs-built_in,
    .m_c2204cc2 pre .hljs-builtin-name,
    .m_c2204cc2 pre .hljs-literal,
    .m_c2204cc2 pre .hljs-type,
    .m_c2204cc2 pre .hljs-params {
      color: var(--code-color-number);
    }

.m_c2204cc2 pre .hljs-string,
    .m_c2204cc2 pre .hljs-symbol,
    .m_c2204cc2 pre .hljs-bullet {
      color: var(--code-color-var);
    }

.m_c2204cc2 pre .hljs-title,
    .m_c2204cc2 pre .hljs-section {
      color: var(--code-color-title);
    }

.m_c2204cc2 pre .hljs-keyword,
    .m_c2204cc2 pre .hljs-selector-tag {
      color: var(--code-color-keyword);
    }

.m_c2204cc2 pre .hljs-emphasis {
      font-style: italic;
    }

.m_c2204cc2 pre .hljs-strong {
      font-weight: 700;
    }

.m_8a991b4f {
  background-color: var(--mantine-color-body);
}

.m_c2207da6 {
  min-width: calc(1.625rem * var(--mantine-scale));
  height: calc(1.625rem * var(--mantine-scale));
  display: flex;
  justify-content: center;
  align-items: center;
  border: calc(0.0625rem * var(--mantine-scale)) solid;
  border-radius: var(--mantine-radius-default);
  cursor: default;
}

:where([data-mantine-color-scheme='light']) .m_c2207da6 {
    background-color: var(--mantine-color-white);
    border-color: var(--mantine-color-gray-4);
}

:where([data-mantine-color-scheme='dark']) .m_c2207da6 {
    background-color: var(--mantine-color-dark-6);
    border-color: var(--mantine-color-dark-4);
}

.m_c2207da6:where([data-disabled]) {
    cursor: not-allowed;
  }

:where([data-mantine-color-scheme='light']) .m_c2207da6:where([data-disabled]) {
      background-color: var(--mantine-color-gray-1);
      color: var(--mantine-color-gray-5);
}

:where([data-mantine-color-scheme='dark']) .m_c2207da6:where([data-disabled]) {
      background-color: var(--mantine-color-dark-5);
      color: var(--mantine-color-dark-3);
}

.m_c2207da6:where([data-interactive]:not([data-disabled])) {
    cursor: pointer;
  }

@media (hover: hover) {
      :where([data-mantine-color-scheme='light']) .m_c2207da6:where([data-interactive]:not([data-disabled])):hover {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_c2207da6:where([data-interactive]:not([data-disabled])):hover {
        background-color: var(--mantine-color-dark-5);
  }
}

@media (hover: none) {
      :where([data-mantine-color-scheme='light']) .m_c2207da6:where([data-interactive]:not([data-disabled])):active {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_c2207da6:where([data-interactive]:not([data-disabled])):active {
        background-color: var(--mantine-color-dark-5);
  }
}

.m_c2207da6:where([data-active]) {
    background-color: var(--mantine-primary-color-light);
    color: var(--mantine-primary-color-light-color);
  }

@media (hover: hover) {

  .m_c2207da6:where([data-active]):hover {
      background-color: var(--mantine-primary-color-light-hover);
  }
}

@media (hover: none) {

  .m_c2207da6:where([data-active]):active {
      background-color: var(--mantine-primary-color-light-hover);
  }
}

.m_2ab47ef2 {
  display: flex;
  background-color: var(--mantine-color-body);
}

.m_2ab47ef2 :where([data-rich-text-editor-control]) {
    border-radius: 0;
  }

.m_2ab47ef2 :where([data-rich-text-editor-control]):where(:not(:last-of-type)) {
      border-inline-end-width: 0;
    }

.m_2ab47ef2 :where([data-rich-text-editor-control]):where(:last-of-type) {
      border-start-end-radius: var(--mantine-radius-default);
      border-end-end-radius: var(--mantine-radius-default);
    }

.m_2ab47ef2 :where([data-rich-text-editor-control]):where(:first-of-type) {
      border-start-start-radius: var(--mantine-radius-default);
      border-end-start-radius: var(--mantine-radius-default);
    }

.m_b67b711e {
  display: flex;
}

.m_296cf94c {
  border-start-end-radius: 0;
  border-end-end-radius: 0;
  border-inline-end: 0;
}

.m_cfef614 {
  border: calc(0.0625rem * var(--mantine-scale)) solid;
  color: var(--mantine-color-text);
  height: calc(1.5rem * var(--mantine-scale));
  width: calc(1.5rem * var(--mantine-scale));
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: var(--mantine-radius-default);
}

:where([data-mantine-color-scheme='light']) .m_cfef614 {
    background-color: var(--mantine-color-white);
    border-color: var(--mantine-color-gray-4);
}

:where([data-mantine-color-scheme='dark']) .m_cfef614 {
    background-color: var(--mantine-color-dark-7);
    border-color: var(--mantine-color-dark-4);
}

.m_cfef614:where([data-active]) {
    background-color: var(--mantine-primary-color-light);
    color: var(--mantine-primary-color-filled);
  }

@media (hover: hover) {

  .m_cfef614:where([data-active]):hover {
      background-color: var(--mantine-primary-color-light-hover);
  }
}

@media (hover: none) {

  .m_cfef614:where([data-active]):active {
      background-color: var(--mantine-primary-color-light-hover);
  }
}

.m_3b28e7bb {
  border-start-start-radius: 0;
  border-end-start-radius: 0;
}

.m_4574a3c4 {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  gap: var(--mantine-spacing-sm);
  top: var(--rte-sticky-offset, 0);
  background-color: var(--mantine-color-body);
  z-index: 1;
  border-start-end-radius: var(--mantine-radius-default);
  border-start-start-radius: var(--mantine-radius-default);
  border-bottom: calc(0.0625rem * var(--mantine-scale)) solid;
  padding: var(--mantine-spacing-xs) var(--mantine-spacing-md);
}

:where([data-mantine-color-scheme='light']) .m_4574a3c4 {
    border-color: var(--mantine-color-gray-4);
}

:where([data-mantine-color-scheme='dark']) .m_4574a3c4 {
    border-color: var(--mantine-color-dark-4);
}

.m_4574a3c4:where([data-sticky]) {
    position: sticky;
  }

.m_8b44009a {
  list-style-type: none;
  padding: 0;
  padding-inline-start: 0;
}

.m_8b44009a :where(li) {
    margin: 0;
    padding: 0;
    display: flex;
  }

.m_8b44009a :where(ul) {
    margin-top: calc(0.3125rem * var(--mantine-scale));
  }

.m_8b44009a p {
    margin: 0;
    padding: 0;
  }

.m_8b44009a :where(label) {
    display: inline-block;
  }

.m_8b44009a :where(input) {
    cursor: pointer;
    appearance: none;
    width: calc(1.125rem * var(--mantine-scale));
    height: calc(1.125rem * var(--mantine-scale));
    border: calc(0.0625rem * var(--mantine-scale)) solid;
    border-radius: var(--mantine-radius-default);
    vertical-align: middle;
    position: relative;
  }

:where([data-mantine-color-scheme='light']) .m_8b44009a :where(input) {
      background-color: var(--mantine-color-white);
      border-color: var(--mantine-color-gray-4);
}

:where([data-mantine-color-scheme='dark']) .m_8b44009a :where(input) {
      background-color: var(--mantine-color-dark-6);
      border-color: var(--mantine-color-dark-4);
}

.m_8b44009a :where(input):checked {
      background-color: var(--mantine-primary-color-filled);
      border-color: var(--mantine-primary-color-filled);
    }

.m_8b44009a :where(input):checked::before {
        position: absolute;
        content: '';
        inset: 0;
        background-image: url('data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGZpbGw9Im5vbmUiIHZpZXdCb3g9IjAgMCAxMCA3Ij48cGF0aCBmaWxsPSIjZmZmIiBkPSJNNCA0LjU4NkwxLjcwNyAyLjI5M0ExIDEgMCAxMC4yOTMgMy43MDdsMyAzYS45OTcuOTk3IDAgMDAxLjQxNCAwbDUtNUExIDEgMCAxMDguMjkzLjI5M0w0IDQuNTg2eiIvPjwvc3ZnPg==');
        background-repeat: no-repeat;
        background-size: calc(0.625rem * var(--mantine-scale));
        background-position: center;
      }

.m_8b44009a :where(li > label) {
    margin-inline-end: var(--mantine-spacing-sm);
  }

.m_d46a4834 {
  position: relative;
  border: calc(0.0625rem * var(--mantine-scale)) dashed;
  color: var(--mantine-color-text);
  padding: var(--mantine-spacing-md);
  border-radius: var(--dropzone-radius);
  cursor: pointer;
  user-select: none;
  transition:
    background-color 100ms ease,
    border-color 100ms ease;
}

  .m_d46a4834:where([data-loading]),
  .m_d46a4834:where(:not([data-activate-on-click])) {
    cursor: default;
  }

  :where([data-mantine-color-scheme='light']) .m_d46a4834 {
    background-color: var(--mantine-color-white);
    border-color: var(--mantine-color-gray-4);
}

  :where([data-mantine-color-scheme='dark']) .m_d46a4834 {
    background-color: var(--mantine-color-dark-6);
    border-color: var(--mantine-color-dark-4);
}

  @media (hover: hover) {
      :where([data-mantine-color-scheme='light']) .m_d46a4834:hover:where([data-activate-on-click]:not([data-loading])) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_d46a4834:hover:where([data-activate-on-click]:not([data-loading])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  @media (hover: none) {
      :where([data-mantine-color-scheme='light']) .m_d46a4834:active:where([data-activate-on-click]:not([data-loading])) {
        background-color: var(--mantine-color-gray-0);
  }

      :where([data-mantine-color-scheme='dark']) .m_d46a4834:active:where([data-activate-on-click]:not([data-loading])) {
        background-color: var(--mantine-color-dark-5);
  }
}

  .m_d46a4834:where([data-accept]) {
    background-color: var(--dropzone-accept-bg);
    border-color: var(--dropzone-accept-bg);
    color: var(--dropzone-accept-color);
  }

  .m_d46a4834:where([data-reject]) {
    background-color: var(--dropzone-reject-bg);
    border-color: var(--dropzone-reject-bg);
    color: var(--dropzone-reject-color);
  }

.m_b85f7144 {
  pointer-events: none;
  user-select: none;
}

.m_b85f7144:where([data-enable-pointer-events]) {
    pointer-events: all;
  }

.m_96f6e9ad {
  position: fixed;
  inset: 0;
  background-color: var(--mantine-color-body);
  display: flex;
  flex-direction: column;
  padding: var(--mantine-spacing-xs);
  transition: opacity 100ms ease;
}

.m_96f6e9ad .m_7946116d {
    flex: 1;
  }

.m_17884d0f {
  --carousel-height: auto;
  position: relative;
}

.m_a2dae653 {
  height: var(--carousel-height);
  overflow: hidden;
}

.m_fcd81474 {
  display: flex;
  flex-direction: row;
  height: var(--carousel-height);
}

:where([data-include-gap-in-size]) .m_fcd81474:where([data-orientation='vertical']) {
      margin-bottom: calc(var(--carousel-slide-gap) * -1);
    }

:where([data-include-gap-in-size]) .m_fcd81474:where([data-orientation='horizontal']) {
      margin-inline-end: calc(var(--carousel-slide-gap) * -1);
    }

.m_fcd81474:where([data-orientation='vertical']) {
    flex-direction: column;
  }

.m_39bc3463 {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: space-between;
  z-index: 1;
  pointer-events: none;
}

.m_39bc3463:where([data-orientation='vertical']) {
    inset-inline-start: calc(50% - var(--carousel-control-size) / 2);
    top: 0;
    bottom: 0;
    flex-direction: column;
    padding: var(--carousel-controls-offset) 0;
  }

.m_39bc3463:where([data-orientation='horizontal']) {
    inset-inline-start: 0;
    inset-inline-end: 0;
    top: calc(50% - var(--carousel-control-size) / 2);
    flex-direction: row;
    padding: 0 var(--carousel-controls-offset);
  }

.m_64f58e10 {
  display: flex;
  justify-content: center;
  align-items: center;
  min-width: var(--carousel-control-size);
  min-height: var(--carousel-control-size);
  border-radius: var(--carousel-control-size);
  pointer-events: all;
  background-color: var(--mantine-color-white);
  color: var(--mantine-color-black);
  box-shadow: var(--mantine-shadow-md);
  border: 1px solid var(--mantine-color-gray-3);
  transition: opacity 100ms ease;
}

:where([data-mantine-color-scheme='light']) .m_64f58e10 {
    opacity: 0.85;
}

:where([data-mantine-color-scheme='dark']) .m_64f58e10 {
    opacity: 0.65;
}

@media (hover: hover) {

  .m_64f58e10:hover {
    opacity: 1;
  }
}

@media (hover: none) {

  .m_64f58e10:active {
    opacity: 1;
  }
}

.m_71ea3ab1 {
  position: absolute;
  display: flex;
  justify-content: center;
  gap: calc(0.5rem * var(--mantine-scale));
  pointer-events: none;
}

.m_71ea3ab1:where([data-orientation='vertical']) {
    bottom: 0;
    top: 0;
    inset-inline-end: var(--mantine-spacing-md);
    flex-direction: column;
  }

.m_71ea3ab1:where([data-orientation='horizontal']) {
    bottom: var(--mantine-spacing-md);
    inset-inline-start: 0;
    inset-inline-end: 0;
    flex-direction: row;
  }

.m_eae68602 {
  pointer-events: all;
  border-radius: var(--mantine-radius-xl);
  box-shadow: var(--mantine-shadow-sm);
  opacity: 0.6;
  background-color: var(--mantine-color-white);
}

@media (hover: hover) {

  .m_eae68602:hover {
    opacity: 1;
  }
}

@media (hover: none) {

  .m_eae68602:active {
    opacity: 1;
  }
}

.m_eae68602:where([data-active]) {
    opacity: 1;
  }

.m_eae68602:where([data-orientation='vertical']) {
    width: calc(0.3125rem * var(--mantine-scale));
    height: calc(1.5625rem * var(--mantine-scale));
  }

.m_eae68602:where([data-orientation='horizontal']) {
    width: calc(1.5625rem * var(--mantine-scale));
    height: calc(0.3125rem * var(--mantine-scale));
  }

.m_d98df724 {
  position: relative;
  flex: 0 0 var(--carousel-slide-size, 100%);
}

.m_17884d0f:where([data-include-gap-in-size]) .m_d98df724:where([data-orientation='vertical']) {
      padding-bottom: var(--carousel-slide-gap);
    }

.m_17884d0f:where([data-include-gap-in-size]) .m_d98df724:where([data-orientation='horizontal']) {
      padding-inline-end: var(--carousel-slide-gap);
    }

.m_17884d0f:where(:not([data-include-gap-in-size])) .m_d98df724:where([data-orientation='vertical']) {
      margin-bottom: var(--carousel-slide-gap);
    }

.m_17884d0f:where(:not([data-include-gap-in-size])) .m_d98df724:where([data-orientation='horizontal']) {
      margin-inline-end: var(--carousel-slide-gap);
    }

